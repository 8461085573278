import React from "react";
import { AlertIcon,Alert } from "@jsluna/alert";
import { InfoCircle } from "@jsluna/icons";
import { Link } from "@jsluna/link";

const InfoBanner = () => {
    return (
        <div>
    <Alert variant="info" open>
        <AlertIcon>
    <InfoCircle aria-label="Information" role="img" />
  </AlertIcon>
  This site is now deprecated. Find our most up to date components and guidance in the {' '}
  <Link color="light" href=" https://design-systems.sainsburys.co.uk">Sainsbury&apos;s Design Systems</Link>
    </Alert>
    </div>
    )
};
  
  export default InfoBanner;
