import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { ExamplesThemeProvider } from '@utils/theme'

import { Page, PageBody, PageFooter } from '@jsluna/site-layout'
import { Container, GridWrapper, GridItem } from '@jsluna/grid'
import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { Card } from '@jsluna/card'
import { TextInputField } from '@jsluna/form'
import { Link } from '@jsluna/link'
import { Section } from '@jsluna/section'
import { Body2, Display2, Display1 } from '@jsluna/typography'
import InfoBanner from "@components/info-banner"

import Button from '../button'
import Sidebar from '../sidebar'
import Footer from '../footer'
import MarkdownLink from '../markdown-link'
import GridList, { GridListItem } from '../grid-list'
import LogosContainer from '../logos-container/index.jsx'
import Masthead from '../masthead'
import Example from '../example'
import ThemeSelector from '../theme-selector'
import GetInTouch from '../get-in-touch'
import {
  Tabs,
  Tab,
  TableTab,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '../tabs'
import TokensLookup from '../tokens-lookup'
import { TableCellResult } from '../design-tokens'

import ImageWithCaption from '../image-with-caption'
import StatePageBrandExamplesSection from '../state-page-brand-examples'
import AZNavigation from '../az-navigation'
import BackToTopLink from '../back-to-top-link'
import FeedbackThumbs from '../feedback-thumbs'

import HeadHelmet from '@components/head-helmet'

import links from '@utils/externalLinks'
import TableOfContents from '@components/table-of-contents'
import { Guideline, Do, Dont, Box } from '@components/guideline'

import { useLocalStorage } from '@utils/useLocalStorage'

import '../../scss/main.scss'

const Layout = ({ pageContext, children, path }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const showThemeSelector = pageContext?.frontmatter?.showThemeSelector

  const [theme, setTheme] = useLocalStorage('theme', {
    name: 'sainsburys',
    punctualName: "Sainsbury's",
  })

  const value = { theme, setTheme, hasThemeSelector: showThemeSelector }
  const defaultTheme = {
    theme: {
      name: 'sainsburys',
      punctualName: "Sainsbury's",
    },
    setTheme,
    hasThemeSelector: showThemeSelector,
  }

  return (
    
    <ExamplesThemeProvider value={showThemeSelector ? value : defaultTheme}>
      <div>
      <InfoBanner></InfoBanner>
      </div>
      <GridWrapper gutterSize="zero">
        <GridItem
          size={{ xs: '1/1', sm: '1/3', md: '1/4' }}
          className="o-sticky-top c-navbar--container"
          style={{ zIndex: '999' }}
        >
          <HeadHelmet title={pageContext?.frontmatter?.title} />
          <Sidebar
            title={data.site.siteMetadata.title}
            path={path}
            hasThemeSelector={showThemeSelector}
          />
        </GridItem>
        <GridItem
          size={{ xs: '1/1', sm: '2/3', md: '3/4' }}
          id="main"
          element="main"
        >
          <Masthead
            title={pageContext?.frontmatter?.title}
            overviewTitle={pageContext?.frontmatter?.overviewTitle}
            tagline={pageContext?.frontmatter?.tagline}
          />
          <Page>
            <PageBody>
              <Container soft={true} size="xs">
                <MDXProvider
                  components={{
                    a: MarkdownLink,
                    AZNavigation,
                    BackToTopLink,
                    Body2,
                    Button,
                    ButtonGroupWrapper,
                    ButtonGroupPrimary,
                    ButtonGroupSecondary,
                    Box,
                    Card,
                    Display1,
                    Display2,
                    GridList,
                    GridListItem,
                    links,
                    Link,
                    TokensLookup,
                    TableOfContents,
                    Guideline,
                    Do,
                    Dont,
                    Example,
                    FeedbackThumbs,
                    GetInTouch,
                    LogosContainer,
                    Section,
                    Tabs,
                    Tab,
                    TableTab,
                    TableHeader,
                    TableBody,
                    TableRow,
                    TableCell,
                    TableCellResult,
                    TextInputField,
                    ImageWithCaption,
                    StatePageBrandExamplesSection,
                  }}
                >
                  {children}
                </MDXProvider>
              </Container>
            </PageBody>
            <PageFooter>
              <Footer
                repoUrl={data.site.siteMetadata.repoUrl}
                isMasterBranch={process.env.LUNA_ENV === 'production'}
                pathname={pageContext}
              />
            </PageFooter>
          </Page>
        </GridItem>
      </GridWrapper>
      {showThemeSelector && <ThemeSelector />}
    </ExamplesThemeProvider>
  )
}

export default Layout
