import { useStaticQuery, graphql } from 'gatsby'

export const useQueryData = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(
        sort: {
          fields: [frontmatter___mainNavigation, frontmatter___title]
          order: ASC
        }
        filter: { frontmatter: { hidden: { ne: true } } }
      ) {
        nodes {
          frontmatter {
            title
            singular
            navTitle
            overviewTitle
            hidden
          }
          id
          slug
        }
      }
      file(ext: { eq: ".svg" }, name: { eq: "luna-avatar" }) {
        id
        name
        publicURL
      }
    }
  `)

  return { data }
}
