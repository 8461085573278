import React from 'react'
import { Footer, Modal, ModalHeading, Link } from '@jsluna/react'
import { Container, FlagWrapper, FlagBody, FlagComponent } from '@jsluna/grid'
import links from '@utils/externalLinks'

const LnFooter = ({ repoUrl, pathname, isMasterBranch }) => {
  //   const splitPath = pathname.split("/").filter(Boolean);
  //   const path = pathname && `edit/master/src/pages/${pathname}index.mdx`;

  const footerLinks = [
    { title: 'Privacy hub', url: links.privacy.privacyHub },
    { title: 'Cookie settings', url: '#' },
    { title: 'Cookies policy', url: links.privacy.cookiePolicy },
    { title: 'Cookie list', url: '#' },
    { title: 'Feedback', url: links.feedback },
  ]

  const [isOpen, setIsOpen] = React.useState(false)

  const CookieLink = ({ type, children }) => {
    return type === 'modal' ? (
      <Link
        element="button"
        className="ln-o-bare-link c-ot-modal-link"
        onClick={() => setIsOpen(true)}
        bare
      >
        {children}
      </Link>
    ) : (
      <Link
        element="button"
        id="ot-sdk-btn"
        bare
        className="ot-sdk-show-settings"
        style={{ fontSize: 'small' }}
      >
        {children}
      </Link>
    )
  }

  return (
    <Footer className="ln-u-soft-ends-xl c-footer">
      <Container>
        <FlagWrapper
          respondAt="md"
          className="ln-u-caption c-footer--flag-wrapper"
        >
          <FlagBody className="ln-u-push-right-lg c-footer--flag-body">
            <span className="ln-u-display-block ln-u-font-weight-bold">
              &copy; J Sainsbury plc {new Date().getFullYear()}
            </span>
            <span className="ln-u-display-block">
              The digital assets and code included in these guidelines may only
              be used with the permission of, and for the benefit of, the J
              Sainsbury plc Group.
            </span>
          </FlagBody>
          <FlagComponent alignment="top" className="c-footer--flag-component">
            <div className="ln-u-display-flex ln-u-flex-wrap">
              {footerLinks.map((link, index) => (
                <span
                  className="ln-u-display-flex ln-u-align-items-center ln-u-text-align-center"
                  key={link.url}
                >
                  {(() => {
                    switch (link.title) {
                      case 'Cookie settings':
                        return <CookieLink>{link.title}</CookieLink>
                      case 'Cookie list':
                        return (
                          <CookieLink type="modal">{link.title}</CookieLink>
                        )
                      default:
                        return (
                          <a
                            href={link.url}
                            className="ln-o-bare-link"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link.title}
                          </a>
                        )
                    }
                  })()}
                  {index !== footerLinks.length - 1 && (
                    <span className="ln-u-push-sides-sm">|</span>
                  )}
                </span>
              ))}
            </div>
          </FlagComponent>
        </FlagWrapper>
      </Container>
      <Modal
        small
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        headingId="info-modal"
        className="ln-u-color-dark"
        style={{ zIndex: 1000 }}
      >
        <ModalHeading element="h3">Cookie policy</ModalHeading>

        <div id="ot-sdk-cookie-policy"></div>
      </Modal>
    </Footer>
  )
}

export default LnFooter
