import React from 'react'
import { Container, GridWrapper, GridItem } from '@jsluna/grid'
import { pascalCase } from 'change-case'
import ReactMarkdown from 'react-markdown'

const Masthead = ({ title, tagline, overviewTitle }) => {
  const images = {
    Accessibility: 'accessibility',
    Colour: 'colour',
    Copy: 'copy',
    Foundations: 'foundations',
    GettingStarted: 'getting-started',
    Luna: 'homepage',
    Icons: 'icons',
    Layout: 'layout',
    Resources: 'resources',
    Typography: 'typography',
  }
  const hasBackgroundImage = images.hasOwnProperty(pascalCase(title))

  return (
    <div
      className={`c-masthead c-masthead--bg c-masthead--bg__${
        images[pascalCase(title)]
      }`}
    >
      <Container soft={true} size="xs">
        <GridWrapper>
          <GridItem
            size={
              hasBackgroundImage ? { md: '1/2', lg: '4/6', xxl: '1/1' } : null
            }
          >
            <div className="ln-u-soft-ends">
              <h1 className="c-masthead--title">
                {overviewTitle ? overviewTitle : title}
              </h1>
              <div className="c-masthead--body">
                <ReactMarkdown linkTarget="_blank">{tagline}</ReactMarkdown>
              </div>
            </div>
          </GridItem>
        </GridWrapper>
      </Container>
    </div>
  )
}

export default Masthead
