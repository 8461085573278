import React, { useContext } from 'react'
import { ExamplesThemeContext } from '@utils/theme'

import SainsburysFavicon from '@images/svg/favicons/sainsburys.svg'
import ArgosFavicon from '@images/svg/favicons/argos.svg'
import NectarFavicon from '@images/svg/favicons/nectar.svg'
import TuFavicon from '@images/svg/favicons/tu.svg'
import HabitatFavicon from '@images/svg/favicons/habitat.svg'

const brandThemes = [
  {
    name: 'sainsburys',
    punctualName: "Sainsbury's",
    icon: <SainsburysFavicon />,
  },
  { name: 'argos', punctualName: 'Argos', icon: <ArgosFavicon /> },
  { name: 'habitat', punctualName: 'Habitat', icon: <HabitatFavicon /> },
  { name: 'tu', punctualName: 'Tu', icon: <TuFavicon /> },
  { name: 'nectar', punctualName: 'Nectar', icon: <NectarFavicon /> },
]

const ThemeSelector = () => {
  const { theme, setTheme } = useContext(ExamplesThemeContext)
  return (
    <div className="c-theme-selector" id="theme-selector">
      <span id="theme-selector-text" className="ln-u-visually-hidden@max-xs">
        Select a theme to demo:
      </span>
      <div role="radiogroup" aria-labelledby="theme-selector-text">
        {brandThemes.map(brandTheme => (
          <button
            key={brandTheme.name}
            onClick={() =>
              setTheme({
                name: brandTheme.name,
                punctualName: brandTheme.punctualName,
              })
            }
            className={`ds-theme--${brandTheme.name} c-theme-selector--icon ${
              brandTheme.name === theme.name && `is-active`
            }`}
            role="radio"
            aria-checked={brandTheme.name === theme.name ? 'true' : 'false'}
          >
            {brandTheme.icon}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ThemeSelector
