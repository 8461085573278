import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { Header as LnHeader, HeaderLogo, HeaderItem } from '@jsluna/header'
import { useQueryData } from './useQueryData'
import NavItems from './NavItems'
import { buildHierarchichalNav, getNavWithParent } from './helpers'
import { NavProvider } from './NavContext'

const Sidebar = ({ title, path, hasThemeSelector }) => {
  const { data } = useQueryData()
  const nav = useMemo(() => {
    const navWithParent = getNavWithParent(data)
    return buildHierarchichalNav(navWithParent)
  }, [data])

  return (
    <NavProvider path={path}>
      <LnHeader
        className="c-navbar"
        mainId="main"
        drawer={true}
        tabBar="min-sm"
        skipLinks={
          hasThemeSelector && [
            {
              title: 'Skip to component theme selector',
              id: 'theme-selector',
            },
          ]
        }
      >
        <HeaderLogo hard={true}>
          <Link to="/">
            <div className="c-logo-container">
              <img
                src={data.file?.publicURL}
                role="presentation"
                alt={data.file.name}
              />
              <div>{title}</div>
            </div>
          </Link>
        </HeaderLogo>

        <HeaderItem
          element="nav"
          className="ln-c-header__item--nav c-navbar"
          tabBar="max-sm"
          drawer="header"
          aria-label="primary"
        >
          <NavItems nav={nav} />
        </HeaderItem>
      </LnHeader>
    </NavProvider>
  )
}

export default Sidebar
