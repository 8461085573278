import React from 'react'

import SainsburysInactiveExample from '@images/states/sainsburys-inactive-example.svg'
import SainsburysActiveExample from '@images/states/sainsburys-active-example.svg'
import HoveredButtonState from '@images/states/hovered-button-state.svg'
import HoveredCheckboxState from '@images/states/hovered-checkbox-state.svg'
import DisabledButtonState from '@images/states/disabled-button-state.svg'
import DisabledRadioState from '@images/states/disabled-radio-state.svg'
import ErrorFormState from '@images/states/error-form-state.svg'
import StateTypeActiveFocused from '@images/states/state-type-active-focused.svg'
import StateTypeError from '@images/states/state-type-error.svg'
import StateTypeInactiveDisabled from '@images/states/state-type-inactive-disabled.svg'
import StateTypeInactiveHovered from '@images/states/state-type-inactive-hovered.svg'
import StateTypeInactiveNormal from '@images/states/state-type-inactive-normal.svg'

import * as LayoutImages from '@images/layout'
import * as ExampleImages from '@images/examples'

const images = {
  'sainsburys-inactive': <SainsburysInactiveExample />,
  'sainsburys-active': <SainsburysActiveExample />,
  'hovered-button': <HoveredButtonState />,
  'hovered-checkbox': <HoveredCheckboxState />,
  'disabled-button': <DisabledButtonState />,
  'disabled-radio': <DisabledRadioState />,
  'error-form': <ErrorFormState />,
  'input-active-focused': <StateTypeActiveFocused />,
  'input-error': <StateTypeError />,
  'input-inactive-disabled': <StateTypeInactiveDisabled />,
  'input-inactive-hovered': <StateTypeInactiveHovered />,
  'input-inactive-normal': <StateTypeInactiveNormal />,
  // Layout Page Images
  'layout-4px-baseline-grid': LayoutImages.LayoutFourPxBaselineGrid,
  'layout-8px-baseline-grid': LayoutImages.LayoutEightPxBaselineGrid,
  'layout-16px-padding-on-mobile': LayoutImages.LayoutSixteenPxPaddingMobile,
  'layout-24px-padding-on-mobile': LayoutImages.LayoutTwentyFourPxPaddingMobile,
  'layout-aspect-ratios': LayoutImages.LayoutAspectRatios,
  'layout-design-templates': LayoutImages.LayoutDesignTemplates,
  'layout-fixed-grids': LayoutImages.LayoutFixedGrids,
  'layout-fluid-grids': LayoutImages.LayoutFluidGrids,
  'layout-grid-columns': LayoutImages.LayoutGridColumns,
  'layout-grid-gutters': LayoutImages.LayoutGridGutters,
  'layout-grid-margins': LayoutImages.LayoutGridMargins,
  'layout-touch-target-minimum': LayoutImages.LayoutTouchTargetMinimum,
  // Kiosk page Images
  'aspect-ratio': ExampleImages.AspectRatio,
  'kiosk-resolution': ExampleImages.KioskResolution,
}

const ImageWithCaption = ({ imageName, alt, caption, type }) => {
  return (
    <figure>
      {type && type.toLowerCase() !== 'svg' ? (
        <img src={images[imageName]} alt={alt} />
      ) : (
        images[imageName]
      )}
      {caption && (
        <figcaption style={{ fontSize: 'small' }}>{caption}</figcaption>
      )}
    </figure>
  )
}

export default ImageWithCaption
